import React from "react";
import { InputLabel, FormControl, Select } from "@material-ui/core";

interface SelectOption<T extends string | number> {
    value: T | null;
    label: string;
}

interface Props<T extends string | number> {
    value: T | null;
    onChange: (value: T | null) => void;
    options: SelectOption<T>[];
    label?: string;
    emptyOption?: boolean;
    disabled?: boolean;
}

function SelectField<T extends string | number>({
    value,
    onChange,
    options,
    label,
    emptyOption = true,
    disabled = false,
    ...props
}: Props<T>) {
    const opts = emptyOption
        ? [{ value: null, label: "" }, ...options]
        : options;

    // const isInOptions = opts.find((opt) => opt.value === value) !== undefined;
    // if (!isInOptions) {
    //     onChange(null);
    // }

    function mask(value: T | null) {
        if (value === null) {
            return "";
        }
        return value;
    }

    function unmask(value: T) {
        if (value === "") {
            return null;
        }
        return value;
    }

    return (
        <div className="field-container">
            <FormControl variant="outlined">
                <InputLabel>{label}</InputLabel>
                <Select
                    native
                    label={label}
                    value={mask(value)}
                    onChange={(e) => onChange(unmask(e.target.value as T))}
                    disabled={disabled}
                >
                    {(opts || []).map((opt: SelectOption<T>) => (
                        <option key={opt.value} value={opt.value || ""}>
                            {opt.label}
                        </option>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default SelectField;
