import { PrintModeActionTypes } from "../actionTypes";

const setPrintMode = (value: boolean) => ({
    type: PrintModeActionTypes.SET_PRINT_MODE,
    payload: {
        value,
    },
});

export { setPrintMode };
