import { ResourceItemType } from "../../helpers/api";
import {BranchItemType} from "../../helpers/dataProcessor";

export const getBranchItems = (store: any): BranchItemType[] => {
    return store.branchItems.branchItems;
};

export const getBranchItem = (store: any, id: string): BranchItemType => {
	return store.branchItems.branchItems.find((i: BranchItemType) => i.itemNr === id);
};

export const getBranchItemsConflicts = (store: any): string[] => {
	return store.branchItems.conflicts;
};

export const getAvailableResourcesQty = (store: any): string[] => {
	return store.branchItems.availableResourcesQty;
};

export const getResourceItems = (store: any): ResourceItemType[] => {
    return store.branchItems.resourceItems;
};
