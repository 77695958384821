import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../assets/lang/en.json";
import de from "../assets/lang/de.json";

i18n.use(initReactI18next).init({
    resources: { de, en },
    lng: "de",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
