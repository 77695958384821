import {UserType} from "../reducers/userReducer";

export const getUser = (store: any): UserType => {
    return store.user;
};

export const getUserId = (store: any): string => {
	return store.user?.userno;
};

export const getIsLogged = (store: any): string => {
	return store.user.islogged;
};

export const getLanguageCode = (store: any): string => {
	return store.user.languageCode?.toLowerCase();
};

