import React from "react";
import cn from "classnames";

import Row from "../Layout/Row";
import Col from "../Layout/Col";

interface RadioLikeOption<T> {
    label: string;
    value: T;
}

interface Props<T> {
    value: T;
    options: RadioLikeOption<T>[];
    onChange: (val: T) => void;
    label?: string;
    inputRef?: React.MutableRefObject<HTMLDivElement>;
    direction?: "row" | "col";
}

function RadioLikeField<T>({
    label,
    value,
    options,
    onChange,
    inputRef,
    direction = "col",
    ...props
}: Props<T>) {
    const Container = (props: any) => {
        if (direction === "row") {
            return (
                <Row className="radiolike-container" wrap="wrap" {...props}>
                    {props.children}
                </Row>
            );
        } else {
            return (
                <Col className="radiolike-container" wrap="wrap" {...props}>
                    {props.children}
                </Col>
            );
        }
    };

    return (
        <div className="field-container">
            <label>{label}</label>
            <div ref={inputRef}>
                <Container>
                    {options.map((o: RadioLikeOption<T>, id: number) => (
                        <div
                            className={cn([
                                "radiolike-option",
                                o.value === value ? "selected" : "",
                            ])}
                            key={id}
                            onClick={(e) => onChange(o.value)}
                        >
                            {o.label}
                        </div>
                    ))}
                </Container>
            </div>
        </div>
    );
}

export default RadioLikeField;
