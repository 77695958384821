import React, { FC } from "react";

import { Button } from "@material-ui/core";

interface Props {
    onClick?: () => void;
    name?: string;
    color?: "inherit" | "primary" | "secondary" | "default" | undefined;
	size?: 'small' | 'medium' | 'large';
    className?: string;
    disabled?: boolean;
}

const ButtonField: FC<Props> = ({
    className,
    color,
    name,
    onClick,
	size,
    disabled = false,
    ...props
}) => {
    return (
        <div className="field-container">
            <Button
                className={className}
                variant="contained"
                color={color || "default"}
                onClick={onClick}
				size={size || "medium"}
                disabled={disabled}
            >
                {props.children}
            </Button>
        </div>
    );
};

export default ButtonField;
