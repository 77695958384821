import { AppConfigActionTypes } from "../actionTypes";

const setAppConfig = (value: any) => ({
    type: AppConfigActionTypes.SET_APP_CONFIG,
    payload: {
        value,
    },
});

export { setAppConfig };
