import React, { FC } from "react";
import cn from "classnames";

import "../assets/icomoon/style.css";

interface Props {
    name: string;
    size?: string;
    color?: string;
    className?: string;
    title?: string;
    style?: React.CSSProperties;
    reff?: React.MutableRefObject<HTMLSpanElement | null>;
    onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    onMouseOver?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const Icon: FC<Props> = ({
    name,
    size,
    color,
    className,
    title,
	style,
    reff,
    onClick,
    onMouseOver,
    onMouseLeave,
    ...props
}) => {
    return (
        <span
            ref={reff}
            className={cn(
                `icon icon-${name}`,
                onClick ? "clickable-icon" : "",
                className
            )}
            style={{ ...(size && { fontSize: size }), color, ...style }}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            title={title}
        />
    );
};

export default Icon;
