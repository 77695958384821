import React from "react";
import { useSelector } from "react-redux";

import { groupEventsByTemplateId } from "../../helpers/calendarHelpers";
import { getEvents } from "../../redux/selectors/eventsSelectors";
import { CalendarEventType } from "../Calendar/CalendarEventRow";
import CalendarEventRowPrintView from "./CalendarEventRowPrintView";

const CalendarBodyPrintView = () => {
    const events = useSelector(getEvents);
    const groupedEvents = groupEventsByTemplateId(events, ["0"]);

    function showEventRow(templateEvents: CalendarEventType[]) {
        if (
            templateEvents[0].parentEventId === "0" ||
            templateEvents[0].parentEventId === templateEvents[0].id
        ) {
            return true;
        }
        return false;
    }

    return (
        <>
            {groupedEvents
                .sort((e1, e2) => {
                    return e1[0].templateId &&
                        e2[0].templateId &&
                        ((e1[0].templateId !== "0" &&
                            e1[0].templateId > e2[0].templateId) ||
                            (e1[0].templateId === "0" && e1[0].id > e2[0].id))
                        ? 1
                        : -1;
                })
                .map((templateEvents, index) => {
                    const childEvents = events.filter(
                        (event) =>
                            event.parentEventId === templateEvents[0].id &&
                            event.id !== templateEvents[0].id
                    );
                    return (
                        <React.Fragment key={index}>
                            {showEventRow(templateEvents) && (
                                <CalendarEventRowPrintView
                                    events={templateEvents}
                                    childEvents={childEvents}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
        </>
    );
};

export default CalendarBodyPrintView;
