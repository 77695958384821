import {BranchItemsActionTypes} from "../actionTypes";
import {BranchItemType} from "../../helpers/dataProcessor";
import {AvailableResourceQtyType} from "../../helpers/calendarHelpers";
import { ResourceItemType } from "../../helpers/api";

interface BranchItemsState {
	branchItems: BranchItemType[];
	conflicts: string[];
	availableResourcesQty: AvailableResourceQtyType;
	resourceItems: ResourceItemType[];
}

function branchItemsReducer(
    state: BranchItemsState = {
    	branchItems: [],
		conflicts: [],
		availableResourcesQty: {},
		resourceItems: [],
	},
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case BranchItemsActionTypes.SET_BRANCHITEMS:
            // console.log("REDUX - set branchItems");
            return {...state, branchItems: action.payload.value};
		case BranchItemsActionTypes.SET_BRANCHITEMS_CONFLICTS:
			// console.log("REDUX - set branchItems conflicts");
			if (JSON.stringify({...state, conflicts: action.payload.value}) === JSON.stringify(state)) {
				return state;
			}

			return {...state, conflicts: action.payload.value};
		case BranchItemsActionTypes.SET_AVAILABLE_RESOURCES_QTY:
			return {...state, availableResourcesQty: action.payload.value};
		case BranchItemsActionTypes.SET_RESOURCE_ITEMS:
			return {...state, resourceItems: action.payload.value};
        default:
            return state;
    }
}

export default branchItemsReducer;
