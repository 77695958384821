import { UserActionTypes } from "../actionTypes";

const setUser = (value: any) => ({
    type: UserActionTypes.SET_USER,
    payload: {
        value,
    },
});

export { setUser };
