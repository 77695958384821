import React, { FC } from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";

interface Props {
    value: boolean;
    onChange: (value: boolean) => void;
    name?: string;
    label?: string;
    disabled?: boolean;
}

const CheckboxField: FC<Props> = ({
    value,
    label,
    name,
    onChange,
    disabled = false,
    ...props
}) => {
    return (
        <div className="field-container">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        onChange={() => onChange(!value)}
                        name={name}
                        disabled={disabled}
                    />
                }
                label={label}
            />
        </div>
    );
};

export default CheckboxField;
