import { CalendarEventType } from "../../components/Calendar/CalendarEventRow";
import { EventsActionTypes } from "../actionTypes";
import {ResourceType} from "../../components/Calendar/CalendarResourceRow";
import {AvailableResourceQtyType} from "../../helpers/calendarHelpers";

const setEvents = (value: CalendarEventType[]) => ({
    type: EventsActionTypes.SET_EVENTS,
    payload: {
        value,
    },
});

const setEventStartEndDate = (
    eventId: string,
    startDate: Date,
    endDate: Date
) => ({
    type: EventsActionTypes.SET_EVENT_START_END_DATE,
    payload: {
        eventId,
        startDate,
        endDate,
    },
});

const setEventResourcesStartEndDates = (
    eventId: string,
    resourceUpdates: {
        resourceId: string;
        startDate: Date;
        endDate: Date;
    }[],
    updateEventDates: boolean
) => ({
    type: EventsActionTypes.SET_EVENT_RESOURCES_START_END_DATES,
    payload: {
        eventId,
        resourceUpdates,
        updateEventDates,
    },
});

const setEventsUpdated = (value: boolean) => ({
    type: EventsActionTypes.SET_EVENTS_UPDATED,
    payload: {
        value,
    },
});

const setEventResources = (eventId: string, resources: ResourceType[]) => ({
	type: EventsActionTypes.SET_EVENT_RESOURCES,
	payload: {
		eventId,
		resources,
	},
});

const setEventResourcesCurrentStock = (newStocks: AvailableResourceQtyType) => ({
	type: EventsActionTypes.SET_EVENT_RESOURCES_CURRENT_STOCK,
	payload: {
		newStocks,
	},
});

const updateEvent = (event: CalendarEventType) => ({
	type: EventsActionTypes.UPDATE_EVENT,
	payload: {
		event,
	},
});


const addEvent = (event: CalendarEventType) => ({
	type: EventsActionTypes.ADD_EVENT,
	payload: {
		event,
	},
});

export {
    setEvents,
    setEventStartEndDate,
    setEventResourcesStartEndDates,
    setEventsUpdated,
	setEventResources,
	setEventResourcesCurrentStock,
	updateEvent,
	addEvent,
};
