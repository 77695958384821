import { AppConfigActionTypes } from "../actionTypes";

export interface AppConfigType {
	[key: string]: string,
}

function appConfigReducer(
    state: AppConfigType = {
		storeManagerUrl: '',
	},
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case AppConfigActionTypes.SET_APP_CONFIG:
            // console.log("REDUX - set appConfig");
            return action.payload.value;
        default:
            return state;
    }
}

export default appConfigReducer;
