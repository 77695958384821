import React, { FC } from "react";
import { useSelector } from "react-redux";
import Draggable from "react-draggable";
import { differenceInMinutes, getDaysInMonth } from "date-fns";

import config from "../../config.json";
import {
    getNonWorkingMinutesInRange,
    getTimeStepInPx,
} from "../../helpers/calendarHelpers";
import { getCalendarDisplayType } from "../../redux/selectors/filterSelectors";
import {
    getCalendarStartDate,
    getWorkTimeEnd,
    getWorkTimeStart,
} from "../../redux/selectors/calendarSelectors";

interface Props {
    id: string;
    className?: string;
    startDate: Date;
    endDate: Date;
    color?: string;
    draggable?: boolean;
    resizable?: boolean;
    onDrag?: (e: any, ui: any) => void;
    onDragStart?: (e: any, ui: any) => void;
    onDragStop?: (e: any, ui: any) => void;
    onLeftResizerMouseDown: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;
    onRightResizerMouseDown: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => void;
    onDoubleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const TimeSlot: FC<Props> = (props) => {
    const {
        id,
        className,
        startDate,
        endDate,
        color,
        draggable = true,
        resizable = true,
        onDrag,
        onDragStart,
        onDragStop,
        onLeftResizerMouseDown,
        onRightResizerMouseDown,
        onDoubleClick,
        children,
    } = props;
    // console.log(">>> TIMESLOT", id);
    const calendarDisplayType = useSelector(getCalendarDisplayType);
    const calendarStartDate = useSelector(getCalendarStartDate);
    const workTimeStart = useSelector(getWorkTimeStart);
    const workTimeEnd = useSelector(getWorkTimeEnd);

    function calcResourceStartPosition(resourceStartDate: Date) {
        const diffInMinutes = differenceInMinutes(
            resourceStartDate,
            calendarStartDate
        );
        const start = diffInMinutes > 0 ? calendarStartDate : resourceStartDate;
        const end = diffInMinutes > 0 ? resourceStartDate : calendarStartDate;
        const multiplier = diffInMinutes > 0 ? 1 : -1;
        return (
            ((diffInMinutes -
                getNonWorkingMinutesInRange(
                    start,
                    end,
                    workTimeStart,
                    workTimeEnd
                ) *
                    multiplier) /
                config.calendar[calendarDisplayType].timeStepInMinutes) *
            getTimeStepInPx(
                calendarDisplayType,
                workTimeStart,
                workTimeEnd,
                getDaysInMonth(calendarStartDate)
            )
        );
    }

    function calcResourceWidth(resourceStartDate: Date, resourceEndDate: Date) {
        return (
            ((differenceInMinutes(resourceEndDate, resourceStartDate) -
                getNonWorkingMinutesInRange(
                    resourceStartDate,
                    resourceEndDate,
                    workTimeStart,
                    workTimeEnd
                )) /
                config.calendar[calendarDisplayType].timeStepInMinutes) *
            getTimeStepInPx(
                calendarDisplayType,
                workTimeStart,
                workTimeEnd,
                getDaysInMonth(calendarStartDate)
            )
        );
    }

    // console.log("---------------");
    // console.log("timeslot rerender");
    // console.log("startDate", startDate);
    // console.log("endDate", endDate);
    const width = calcResourceWidth(startDate, endDate);
    const startX = calcResourceStartPosition(startDate);
    const endX = startX + width;

    // let w = width;
    // let sx = startX;
    // if (startX < 0) {
    //     sx = 0;
    //     w = w + startX;
    // }
    // if (endX > config.calendar.bodyWidth) {
    //     w = w - (endX - config.calendar.bodyWidth);
    // }

    // console.log("width", width);
    // console.log("startX", startX);

    function satisfyRenderConditions() {
        if (width <= 0 || endX < 0 || startX > config.calendar.bodyWidth) {
            return false;
        }
        return true;
    }

	const nodeRef = React.useRef(null);

    return satisfyRenderConditions() ? (
        <Draggable
			nodeRef={nodeRef}
            disabled={!draggable}
            axis="x"
            position={{
                x: startX,
                // x: sx,
                y: 0,
            }}
            cancel="div.resizer"
            grid={[
                getTimeStepInPx(
                    calendarDisplayType,
                    workTimeStart,
                    workTimeEnd,
                    getDaysInMonth(calendarStartDate)
                ),
                0,
            ]}
            onDrag={onDrag}
            onStart={onDragStart}
            onStop={onDragStop}
        >
            <div ref={nodeRef} id={id} className="calendar-timeslot-container">
                <div
                    className={`calendar-timeslot noselect ${className && className}`}
                    style={{
                        width: width,
                        // width: w,
                        backgroundColor: color?.startsWith("rgb")
                            ? color
                            : `#${color}`,
                        cursor: draggable ? "move" : "auto",
                    }}
                    onDoubleClick={onDoubleClick}
                >
                    {resizable && (
                        <>
                            {children}
                            <div
                                className="resizer left"
                                onMouseDown={onLeftResizerMouseDown}
                            />
                            <div
                                className="resizer right"
                                onMouseDown={onRightResizerMouseDown}
                            />
                        </>
                    )}
                </div>
            </div>
        </Draggable>
    ) : (
        <></>
    );
};

export default React.memo(TimeSlot) as FC<Props>;
