import React, { FC } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';

import logo from "../../assets/images/store-logo.gif";
import "./loadingScreen.css";

const LoadingScreen: FC = () => {
    return (
        <div className="loading-container">
            <img src={logo} alt="" />
            <LinearProgress style={{width: 766}} />
        </div>
    )
};

export default LoadingScreen;