import { combineReducers } from "redux";
import eventsReducer from "./eventsReducer";
import filtersReducer from "./filtersReducer";
import calendarReducer from "./calendarReducer";
import filterOptionsReducer from "./filterOptionsReducer";
import printModeReducer from "./printModeReducer";
import branchItemsReducer from "./branchItemsReducer";
import appConfigReducer from "./appConfigReducer";
import userReducer from "./userReducer";

export default combineReducers({
    events: eventsReducer,
    filters: filtersReducer,
    filterOptions: filterOptionsReducer,
    calendar: calendarReducer,
    printMode: printModeReducer,
	branchItems: branchItemsReducer,
	appConfig: appConfigReducer,
	user: userReducer,
});
