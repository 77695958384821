import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDaysInMonth } from "date-fns";

import {updateEventFromAPI} from "../../helpers/api";
import {
	calcEventStartEndDates,
	calcResourceStartEndDates, findOverlappingEvents,
	getEventById,
	getEventByResourceId,
	getResourceById,
	getTimeStepInPx,
	isEventId,
    eventMovable,
    confirmEventMove,
} from "../../helpers/calendarHelpers";
import {
    ResizeReffProps,
    HandlersContext,
    HandlersContextType,
    initHandlersContext,
} from "./Calendar";
import CalendarEventRow, { CalendarEventType } from "./CalendarEventRow";
import { getCalendarDisplayType, getCalendarId, getShowBlockersFilter } from "../../redux/selectors/filterSelectors";
import { getEvents } from "../../redux/selectors/eventsSelectors";
import {
    getCalendarStartDate,
    getLoading,
    getWorkTimeEnd,
    getWorkTimeStart,
} from "../../redux/selectors/calendarSelectors";
import {
	setEventResourcesStartEndDates,
	setEventStartEndDate,
	setEventsUpdated, updateEvent,
} from "../../redux/actions/eventsActions";
import Loader from "../Loader/Loader";
import {setBranchItemsConflicts} from "../../redux/actions/branchItemsActions";
import {getBranchItems} from "../../redux/selectors/branchItemsSelectors";

const CalendarBody = () => {
    // console.log(">>> Calendar BODY");
    const dispatch = useDispatch();
    const events = useSelector(getEvents);
    const branchItems = useSelector(getBranchItems);
    const calendarDisplayType = useSelector(getCalendarDisplayType);
    const calendarStartDate = useSelector(getCalendarStartDate);
    const calendarId = useSelector(getCalendarId);
    const workTimeStart = useSelector(getWorkTimeStart);
    const workTimeEnd = useSelector(getWorkTimeEnd);
    const isLoading = useSelector(getLoading);
    const showBlockers = useSelector(getShowBlockersFilter)
    // const groupedEvents = groupEventsByTemplateId(events, ["0"]);
    const { t } = useTranslation();
    const reffProps = useRef<ResizeReffProps>({
        activeTimeBoxId: null,
        resizeLastUpdateScreenX: null,
        resizeDeltaX: 0,
        isResizeLeftActive: false,
        isResizeRightActive: false,
        resizeStartTime: null,
        resizeEndTime: null,
    });
    const eventsRef = useRef(events);
    const eventBeforeMove = useRef<CalendarEventType | null>(null);
    const [handlersContextValue, setHandlersContextValue] = useState<
        HandlersContextType
    >(initHandlersContext);

    useEffect(() => {
        eventsRef.current = events;
    }, [events]);

    useEffect(() => {
		dispatch(setBranchItemsConflicts(findOverlappingEvents(events, branchItems)));
		// console.log('Conflicts resolved');
	}, [dispatch, events, branchItems]);

    // Used for dragging by left or right event/resource corner
    const mouseUpHandler = useCallback(
        async (e: MouseEvent) => {
            if (
                reffProps.current.isResizeLeftActive ||
                reffProps.current.isResizeRightActive
            ) {
                const updatedEvents = eventsRef.current.filter(
                    (e) => e.updated
                );
				reffProps.current.isResizeLeftActive = false;
				reffProps.current.isResizeRightActive = false;
				reffProps.current.resizeLastUpdateScreenX = null;
				if (updatedEvents.length) {
					for (const event of updatedEvents) {
                        if (confirmEventMove(event)) {
                            const result = window.confirm(t("do_you_really_want_to_change_the_scheduling_of_the_event"));
                            if (result) {
                                await updateEventFromAPI(event);
                                dispatch(updateEvent(event));
                                eventBeforeMove.current = null;
                                return;
                            } else {
                                if (eventBeforeMove.current) {
                                    dispatch(updateEvent(eventBeforeMove.current));
                                }
                                eventBeforeMove.current = null;
                                return;
                            }
                        }
                        eventBeforeMove.current = null;
						await updateEventFromAPI(event);
						dispatch(updateEvent(event));
						// console.log(`Event ${event.id} updated`);
					}
					dispatch(setEventsUpdated(false));
				}
            }
        },
        [dispatch, events]
    );
    
    // Used for dragging by left or right event/resource corner
    const mouseMoveHandler = useCallback(
        (e: MouseEvent) => {
            if (
                reffProps.current.resizeLastUpdateScreenX &&
                reffProps.current.activeTimeBoxId &&
                reffProps.current.resizeStartTime &&
                reffProps.current.resizeEndTime &&
                (reffProps.current.isResizeLeftActive ||
                    reffProps.current.isResizeRightActive)
            ) {
                reffProps.current.resizeDeltaX =
                    e.screenX - reffProps.current.resizeLastUpdateScreenX;
                const daysInMonth = getDaysInMonth(calendarStartDate);
                if (
                    isEventId(
                        reffProps.current.activeTimeBoxId,
                        eventsRef.current
                    )
                ) {
                    const event = getEventById(
                        reffProps.current.activeTimeBoxId,
                        eventsRef.current
                    );
                    const update = calcEventStartEndDates(
                        reffProps.current.resizeDeltaX,
                        event,
                        calendarDisplayType,
                        workTimeStart,
                        workTimeEnd,
                        daysInMonth,
                        reffProps.current.isResizeLeftActive
                            ? {
                                  updateStartDate: true,
                                  updateEndDate: false,
                              }
                            : {
                                  updateStartDate: false,
                                  updateEndDate: true,
                              }
                    );
                    if (update) {
                        dispatch(
                            setEventStartEndDate(
                                update.eventUpdate.eventId,
                                update.eventUpdate.startDate,
                                update.eventUpdate.endDate
                            )
                        );
                        const diff =
                            Math.floor(
                                reffProps.current.resizeDeltaX -
                                    update.stepCount *
                                        getTimeStepInPx(
                                            calendarDisplayType,
                                            workTimeStart,
                                            workTimeEnd,
                                            daysInMonth
                                        )
                            ) * -1;
                        reffProps.current.resizeLastUpdateScreenX =
                            e.screenX + diff;
                    }
                } else {
                    const resource = getResourceById(
                        reffProps.current.activeTimeBoxId,
                        eventsRef.current
                    );
                    const update = calcResourceStartEndDates(
                        reffProps.current.resizeDeltaX,
                        resource,
                        calendarDisplayType,
                        workTimeStart,
                        workTimeEnd,
                        daysInMonth,
                        reffProps.current.isResizeLeftActive
                            ? {
                                  updateStartDate: true,
                                  updateEndDate: false,
                              }
                            : {
                                  updateStartDate: false,
                                  updateEndDate: true,
                              }
                    );
                    if (update) {
                        const event = getEventByResourceId(
                            reffProps.current.activeTimeBoxId,
                            eventsRef.current
                        );
                        if (
							event
							&& !(event.startDate > update.resourceUpdate.startDate)
							&& !(event.endDate < update.resourceUpdate.endDate)
						) {
                            dispatch(
                                setEventResourcesStartEndDates(
                                    event.id,
                                    [update.resourceUpdate],
                                    true
                                )
                            );
                            const diff =
                                Math.floor(
                                    reffProps.current.resizeDeltaX -
                                        update.stepCount *
                                            getTimeStepInPx(
                                                calendarDisplayType,
                                                workTimeStart,
                                                workTimeEnd,
                                                daysInMonth
                                            )
                                ) * -1;
                            reffProps.current.resizeLastUpdateScreenX =
                                e.screenX + diff;
                        }
                    }
                }
            }
        },
        [
            reffProps,
            calendarDisplayType,
            workTimeStart,
            workTimeEnd,
            calendarStartDate,
            dispatch,
        ]
    );

    useEffect(() => {
        window.addEventListener("mousemove", mouseMoveHandler);
        window.addEventListener("mouseup", mouseUpHandler);
        return () => {
            window.removeEventListener("mousemove", mouseMoveHandler);
            window.removeEventListener("mouseup", mouseUpHandler);
        };
    }, [mouseMoveHandler, mouseUpHandler]);

    const handleDrag = useCallback(
        (e: any, ui: any) => {
            if (isEventId(ui.node.id, eventsRef.current)) {
                const event = getEventById(ui.node.id, eventsRef.current);
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
                if (eventBeforeMove.current === null) {
                    eventBeforeMove.current = event;
                }

                const update = calcEventStartEndDates(
                    ui.deltaX,
                    event,
                    calendarDisplayType,
                    workTimeStart,
                    workTimeEnd,
                    getDaysInMonth(calendarStartDate)
                );
                if (update) {
                    dispatch(
                        setEventResourcesStartEndDates(
                            ui.node.id,
                            update.resourcesUpdates,
                            false
                        )
                    );
                    dispatch(
                        setEventStartEndDate(
                            update.eventUpdate.eventId,
                            update.eventUpdate.startDate,
                            update.eventUpdate.endDate
                        )
                    );
                }
            } else {
                const resource = getResourceById(ui.node.id, eventsRef.current);
                const event = getEventById(resource?.eventId || '', eventsRef.current);
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
                const update = calcResourceStartEndDates(
                    ui.deltaX,
                    resource,
                    calendarDisplayType,
                    workTimeStart,
                    workTimeEnd,
                    getDaysInMonth(calendarStartDate)
                );
                if (update) {
                    const event = getEventByResourceId(
                        ui.node.id,
                        eventsRef.current
                    );
                    if (
						event
						&& !(event.startDate > update.resourceUpdate.startDate)
						&& !(event.endDate < update.resourceUpdate.endDate)
					) {
						// console.log(event.startDate > update.resourceUpdate.startDate);
                        dispatch(
                            setEventResourcesStartEndDates(
                                event.id,
                                [update.resourceUpdate],
                                true
                            )
                        );
                    }
                }
            }
        },
        [
            calendarDisplayType,
            workTimeStart,
            workTimeEnd,
            calendarStartDate,
            dispatch,
        ]
    );

    const handleDragStop = useCallback(
        async (e: any, ui: any) => {
            const updatedEvents = eventsRef.current.filter((e) => e.updated);
            if (updatedEvents.length) {
            	for (const event of updatedEvents) {
                    if (confirmEventMove(event)) {
                        const result = window.confirm(t("do_you_really_want_to_change_the_scheduling_of_the_event"));
                        if (result) {
                            await updateEventFromAPI(event);
                            dispatch(updateEvent(event));
                            eventBeforeMove.current = null;
                            return;
                        } else {
                            if (eventBeforeMove.current) {
                                dispatch(updateEvent(eventBeforeMove.current));
                            }
                            eventBeforeMove.current = null;
                            return;
                        }
                    }
                    eventBeforeMove.current = null;
            		await updateEventFromAPI(event);
					dispatch(updateEvent(event));
					// console.log(`Event ${event.id} updated`);
				}
				dispatch(setEventsUpdated(false));
			}
        },
        [dispatch, events]
    );

    const handleLeftResizerMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const timeBoxId =
                e.currentTarget.parentElement &&
                e.currentTarget.parentElement.parentElement
                    ? e.currentTarget.parentElement.parentElement.id
                    : null;
            if (!timeBoxId) {
                return;
            }
            if (isEventId(timeBoxId, eventsRef.current)) {
                const event = getEventById(timeBoxId, eventsRef.current);
                eventBeforeMove.current = event;
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
                reffProps.current.resizeStartTime = event && event.startDate;
                reffProps.current.resizeEndTime = event && event.endDate;
            } else {
                const resource = getResourceById(timeBoxId, eventsRef.current);
                const event = getEventById(resource?.eventId || '', eventsRef.current);
                eventBeforeMove.current = event;
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
                reffProps.current.resizeStartTime =
                    resource && resource.startDate;
                reffProps.current.resizeEndTime = resource && resource.endDate;
            }
            reffProps.current.activeTimeBoxId = timeBoxId;
            reffProps.current.isResizeLeftActive = true;
            reffProps.current.resizeLastUpdateScreenX = e.screenX;
        },
        []
    );

    const handleRightResizerMouseDown = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            const timeBoxId =
                e.currentTarget.parentElement &&
                e.currentTarget.parentElement.parentElement
                    ? e.currentTarget.parentElement.parentElement.id
                    : null;
            if (!timeBoxId) {
                return;
            }
            if (isEventId(timeBoxId, eventsRef.current)) {
                const event = getEventById(timeBoxId, eventsRef.current);
                eventBeforeMove.current = event;
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
                reffProps.current.resizeStartTime = event && event.startDate;
                reffProps.current.resizeEndTime = event && event.endDate;
            } else {
                const resource = getResourceById(timeBoxId, eventsRef.current);
                const event = getEventById(resource?.eventId || '', eventsRef.current);
                eventBeforeMove.current = event;
                if (!eventMovable(event)) {
                    // alert('Event with order cannot be moved');
                    return;
                }
                reffProps.current.resizeStartTime =
                    resource && resource.startDate;
                reffProps.current.resizeEndTime = resource && resource.endDate;
            }
            reffProps.current.activeTimeBoxId = timeBoxId;
            reffProps.current.isResizeRightActive = true;
            reffProps.current.resizeLastUpdateScreenX = e.screenX;
        },
        []
    );

    useEffect(() => {
        setHandlersContextValue({
            handleDrag,
            handleDragStop,
            handleLeftResizerMouseDown,
            handleRightResizerMouseDown,
        });
    }, [
        handleDrag,
        handleDragStop,
        handleLeftResizerMouseDown,
        handleRightResizerMouseDown,
    ]);

    function showEventRow(templateEvents: CalendarEventType[]) {
        if (
            templateEvents[0].parentEventId === "0" ||
            templateEvents[0].parentEventId === templateEvents[0].id
        ) {
            return true;
        }
        return false;
    }

    const filterEvents = (events: CalendarEventType[]) => {
        let result: CalendarEventType[] = events;

        if (calendarId !== '-1') {
            result = result.filter((e) => e.calendarId === calendarId);
        }

        if (!showBlockers) {
            result = result.filter((e) => !['0', '4'].includes(e.orderLinkType));
        }

        return result;
    };

    return !isLoading ? (
        <HandlersContext.Provider value={handlersContextValue}>
            {events && !events.length && (
                <div className="no-events-message">{t("no_events_found")}</div>
            )}
			{filterEvents(events)
            // .filter((e) => !['0', '4'].includes(e.orderLinkType))
            .map((templateEvent, index) => {
				const childEvents = events.filter(
					(event) =>
						event.parentEventId === templateEvent.id &&
						event.id !== templateEvent.id
				);
				return (
					<React.Fragment key={index}>
						{showEventRow([templateEvent]) && (
							<CalendarEventRow
								events={[templateEvent]}
								childEvents={childEvents}
                                showCheckbox={eventMovable(templateEvent)}
							/>
						)}
					</React.Fragment>
				);
			})}
            {/*{groupedEvents*/}
            {/*    .sort((e1, e2) => {*/}
            {/*        return e1[0].templateId &&*/}
            {/*            e2[0].templateId &&*/}
            {/*            ((e1[0].templateId !== "0" &&*/}
            {/*                e1[0].templateId > e2[0].templateId) ||*/}
            {/*                (e1[0].templateId === "0" && e1[0].id > e2[0].id))*/}
            {/*            ? 1*/}
            {/*            : -1;*/}
            {/*    })*/}
            {/*    .map((templateEvents, index) => {*/}
            {/*        const childEvents = events.filter(*/}
            {/*            (event) =>*/}
            {/*                event.parentEventId === templateEvents[0].id &&*/}
            {/*                event.id !== templateEvents[0].id*/}
            {/*        );*/}
            {/*        return (*/}
            {/*            <React.Fragment key={index}>*/}
            {/*                {showEventRow(templateEvents) && (*/}
            {/*                    <CalendarEventRow*/}
            {/*                        events={templateEvents}*/}
            {/*                        childEvents={childEvents}*/}
            {/*                    />*/}
            {/*                )}*/}
            {/*            </React.Fragment>*/}
            {/*        );*/}
            {/*    })*/}
            {/*}*/}
        </HandlersContext.Provider>
    ) : (
        <Loader loading={isLoading} />
    );
};

export default React.memo(CalendarBody);
