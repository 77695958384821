import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setEventSortFilter } from "../../redux/actions/calendarActions";
import { setEvents } from "../../redux/actions/eventsActions";
import { EventSortOptionType } from "../../redux/reducers/calendarReducer";
import { getEventSortFilter } from "../../redux/selectors/calendarSelectors";
import { getEvents } from "../../redux/selectors/eventsSelectors";
import ButtonField from "../Fields/ButtonField";
import Col from "../Layout/Col";
import Row from "../Layout/Row";
import { sortEventsByKey } from "../../helpers/calendarHelpers";

const EventSort: FC = () => {
    const dispatch = useDispatch();
    const eventSort = useSelector(getEventSortFilter);
    const events = useSelector(getEvents);
    const { t } = useTranslation();

    const setEventSort = (key: EventSortOptionType) => {
        dispatch(setEventSortFilter(key));
    }

    useEffect(() => {
        dispatch(setEvents(sortEventsByKey(events, eventSort)));
    }, [eventSort, dispatch]);

    return (
        <Row gap="5px">
            <Col>
                <ButtonField
                    className="sort-events"
                    size="small"
                    color={eventSort === "timestamp" ? "primary" : "default"}
                    onClick={() => setEventSort('timestamp')}
                >
                    {t("updated")}
                </ButtonField>
            </Col>
            <Col>
                <ButtonField
                    className="sort-events"
                    size="small"
                    color={eventSort === "name" ? "primary" : "default"}
                    onClick={() => setEventSort('name')}
                >
                    {t("name")}
                </ButtonField>
            </Col>
            <Col>
                <ButtonField
                    className="sort-events"
                    size="small"
                    color={eventSort === "startDate" ? "primary" : "default"}
                    onClick={() => setEventSort('startDate')}
                >
                    {t("time")}
                </ButtonField>
            </Col>
        </Row>
    );
}

export default EventSort;