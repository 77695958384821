import { CalendarDisplayType } from "../../components/Calendar/Calendar";
import { FilterActionTypes } from "../actionTypes";

const setPickedDateFilter = (value: Date) => ({
    type: FilterActionTypes.SET_FILTER_PICKED_DATE,
    payload: {
        value,
    },
});

const setCalendarIdFilter = (value: string | null) => ({
    type: FilterActionTypes.SET_FILTER_CALENDAR_ID,
    payload: {
        value,
    },
});

const setCalendarDisplayTypeFilter = (value: CalendarDisplayType) => ({
    type: FilterActionTypes.SET_FILTER_CALENDAR_DISPLAY_TYPE,
    payload: {
        value,
    },
});

const setEventsWithoutOrderFilter = (value: boolean) => ({
    type: FilterActionTypes.SET_FILTER_EVENTS_WITHOUT_ORDER,
    payload: {
        value,
    },
});

const setShowBlockersFilter = (value: boolean) => ({
    type: FilterActionTypes.SET_FILTER_SHOW_BLOCKERS,
    payload: {
        value,
    },
});

const setBranchGroupFilter = (value: string | null) => ({
    type: FilterActionTypes.SET_FILTER_BRANCH_GROUP,
    payload: {
        value,
    },
});

const setBranchFilter = (value: string | null) => ({
    type: FilterActionTypes.SET_FILTER_BRANCH,
    payload: {
        value,
    },
});

const setTemplateGroupFilter = (value: string | null) => ({
    type: FilterActionTypes.SET_FILTER_TEMPLATE_GROUP,
    payload: {
        value,
    },
});

const setTemplateFilter = (value: string | null) => ({
    type: FilterActionTypes.SET_FILTER_TEMPLATE,
    payload: {
        value,
    },
});

const setResourceGroupFilter = (value: string | null) => ({
    type: FilterActionTypes.SET_FILTER_RESOURCE_GROUP,
    payload: {
        value,
    },
});

const setResourceFilter = (value: string | null) => ({
    type: FilterActionTypes.SET_FILTER_RESOURCE,
    payload: {
        value,
    },
});

const setSpecialResourceFilter = (value: string | null) => ({
	type: FilterActionTypes.SET_FILTER_SPEACIAL_RESOURCE,
	payload: {
		value,
	},
});

// const setUnasignedResourcesFilter = (value: string | null) => ({
//     type: FilterActionTypes.SET_FILTER_UNASIGNED_RESOURCES,
//     payload: {
//         value,
//     },
// });

const setSearchFilter = (value: string | null) => ({
    type: FilterActionTypes.SET_FILTER_SEARCH,
    payload: {
        value,
    },
});

export {
    setPickedDateFilter,
    setCalendarIdFilter,
    setCalendarDisplayTypeFilter,
    setEventsWithoutOrderFilter,
    setShowBlockersFilter,
    setBranchGroupFilter,
    setBranchFilter,
    setTemplateFilter,
    setResourceGroupFilter,
    setResourceFilter,
	setSpecialResourceFilter,
	setTemplateGroupFilter,
	setSearchFilter,
};
