import React, { FC } from "react";

import TextField from "./TextField";

interface Props {
    value: number | null;
    onChange: (value: number | null) => void;
    decimals?: number;
    label?: string;
    labelType?: "in" | "out";
	readOnly?: boolean;
    disabled?: boolean;
}

const NumberField: FC<Props> = ({
    value,
    onChange,
    decimals = 0,
    label,
    labelType,
    readOnly = false,
    disabled = false,
}) => {
    const unmask = (value: string | null) => {
        const val = value !== null ? parseFloat(value) : null;
        if (typeof val === "number") {
            return parseFloat(val.toFixed(decimals));
        }
        return null;
    };

    return (
        <TextField
            value={value !== null ? value.toString() : ""}
            onChange={(val) => onChange(unmask(val))}
            label={label}
            labelType={labelType}
			style={{direction: 'rtl'}}
			readOnly={readOnly}
            disabled={disabled}
        />
    );
};

export default NumberField;
