import React from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import Row from "../Layout/Row";
import Col from "../Layout/Col";
import { useGetDayName } from "../../helpers/dateHelpers";
import TimeLine from "../Calendar/TimeLine";
import { getTimeLineValues } from "../../helpers/calendarHelpers";
import {
    getCalendarStartDate,
    getWorkTimeEnd,
    getWorkTimeStart,
} from "../../redux/selectors/calendarSelectors";
import CalendarBodyPrintView from "./CalendarBodyPrintView";

const DayCalendarPrintView = () => {
    const calendarStartDate = useSelector(getCalendarStartDate);
    const workTimeStart = useSelector(getWorkTimeStart);
    const workTimeEnd = useSelector(getWorkTimeEnd);
    const timeLineValues = getTimeLineValues(workTimeStart, workTimeEnd, "day");

    return (
        <div className="calendar-container">
            <div className="calendar-head">
                <Row>
                    <Col width={1} className="calendar-head-event"></Col>
                    <Col width={7} align="center" className="calendar-head-day">
                        {useGetDayName(calendarStartDate)}
                        <br />
                        {format(calendarStartDate, "dd.MM.yyyy")}
                    </Col>
                </Row>
            </div>
            <TimeLine timeLineValues={timeLineValues} />
            <div className="calendar-body-container">
                <div className="calendar-body">
                    <div className="body-timeline">
                        {timeLineValues.map((tl, index) => (
                            <React.Fragment key={index}>
                                {tl.showLine && (
                                    <div
                                        className="line"
                                        style={{
                                            marginLeft: `${tl.step}px`,
                                        }}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                    <CalendarBodyPrintView />
                </div>
            </div>
        </div>
    );
};

export default DayCalendarPrintView;
