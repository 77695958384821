import { PrintModeActionTypes } from "../actionTypes";

function printModeReducer(
    state: boolean = false,
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case PrintModeActionTypes.SET_PRINT_MODE:
            // console.log("REDUX - set printMode");
            return action.payload.value;
        default:
            return state;
    }
}

export default printModeReducer;
