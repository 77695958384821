import { FilterOptsActionTypes } from "../actionTypes";
import { FiltersOptionsType } from "../reducers/filterOptionsReducer";

const setFilterOptions = (value: FiltersOptionsType) => ({
    type: FilterOptsActionTypes.SET_FILTER_OPTIONS,
    payload: {
        value,
    },
});

// const setBranchGroupFilterOpts = (
//     value: { value: string; label: string }[]
// ) => ({
//     type: FilterOptsActionTypes.SET_FILTER_OPT_BRANCH_GROUP,
//     payload: {
//         value,
//     },
// });

// const setBranchFilterOpts = (
//     value: { value: string; label: string; groupValue: string }[]
// ) => ({
//     type: FilterOptsActionTypes.SET_FILTER_OPT_BRANCH,
//     payload: {
//         value,
//     },
// });

// const setTemplateGroupFilterOpts = (value: { value: string; label: string }[]) => ({
//     type: FilterOptionsTypes.SET_FILTER_OPT_TEMPLATE_GROUP,
//     payload: {
//         value,
//     },
// });

// const setTemplateFilterOpts = (value: { value: string; label: string }[]) => ({
//     type: FilterOptsActionTypes.SET_FILTER_OPT_TEMPLATE,
//     payload: {
//         value,
//     },
// });

// const setResourceGroupFilterOpts = (
//     value: { value: string; label: string }[]
// ) => ({
//     type: FilterOptsActionTypes.SET_FILTER_OPT_RESOURCE_GROUP,
//     payload: {
//         value,
//     },
// });

// const setResourceFilterOpts = (
//     value: { value: string; label: string; groupValue: string }[]
// ) => ({
//     type: FilterOptsActionTypes.SET_FILTER_OPT_RESOURCE,
//     payload: {
//         value,
//     },
// });

// const setSuppliersFilterOpts = (value: { value: string; label: string }[]) => ({
//     type: FilterOptsActionTypes.SET_FILTER_OPT_SUPPLIERS,
//     payload: {
//         value,
//     },
// });

export {
    setFilterOptions,
    // setBranchGroupFilterOpts,
    // setBranchFilterOpts,
    // setTemplateFilterOpts,
    // setResourceGroupFilterOpts,
    // setResourceFilterOpts,
    // setSuppliersFilterOpts,
};
