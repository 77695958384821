import { CalendarEventType } from "../../components/Calendar/CalendarEventRow";
import { EventsActionTypes } from "../actionTypes";
import {ResourceType} from "../../components/Calendar/CalendarResourceRow";
import {AvailableResourceQtyType} from "../../helpers/calendarHelpers";

function setEventDate(
    state: CalendarEventType[],
    eventId: string,
    startDate: Date,
    endDate: Date
) {
	const eventIdx = state.findIndex(e => e.id === eventId);

	if (eventIdx >= 0) {
		return [
			...state.slice(0, eventIdx),
			{
				...(state[eventIdx]),
				startDate,
				endDate,
				updated: true,
			},
			...state.slice(eventIdx + 1),
		];
	}

	return state;
}

function setResourcesDates(
    events: CalendarEventType[],
    eventId: string,
    resourceUpdates: { resourceId: string; startDate: Date; endDate: Date }[],
    updateEventDates: boolean
) {
	// @ts-ignore
    const [thisEvents, otherEvents] = events.reduce(
        (acc: CalendarEventType[][], event: CalendarEventType) => {
            if (event.id === eventId) {
                return [[...acc[0], event], acc[1]];
            } else {
                return [acc[0], [...acc[1], event]];
            }
        },
        [[], []]
    );
    if (!thisEvents.length) {
        return;
    }
    const thisEvent = thisEvents[0];
    let newEventStartDate = thisEvent.startDate;
    let newEventEndDate = thisEvent.endDate;
    const updatedResources = thisEvent.resources.map((resource) => {
        const updRes = resourceUpdates.find(
            (u) => u.resourceId === resource.id
        );
        if (updRes) {
            if (
                updateEventDates &&
                updRes.startDate.getTime() < newEventStartDate.getTime()
            ) {
                newEventStartDate = updRes.startDate;
            }
            if (
                updateEventDates &&
                updRes.endDate.getTime() > newEventEndDate.getTime()
            ) {
                newEventEndDate = updRes.endDate;
            }
            return {
                ...resource,
                startDate: updRes.startDate,
                endDate: updRes.endDate,
            };
        }
        return resource;
    });

	const eventIdx = events.findIndex(e => e.id === eventId);

	if (eventIdx >= 0) {
		return [
			...events.slice(0, eventIdx),
			{
				...{
					...thisEvent,
					startDate: newEventStartDate,
					endDate: newEventEndDate,
					updated: true,
				},
				resources: updatedResources,
			},
			...events.slice(eventIdx + 1),
		];
	}
	console.error('Error while finding eventId');
	return [];
}

function setEventResources(
	events: CalendarEventType[],
	eventId: string,
	resources: ResourceType[],
) {
	return events.map(event => event.id === eventId ? {...event, resources} : event);
}

const setEventResourcesCurrentStock = (events: CalendarEventType[], newStocks: AvailableResourceQtyType) => {
	return events.map((e) => {
		const resources = e.resources.map((r) => {
			let currentStock: number;
			if (Object.keys(newStocks).includes(r.id)) {
				currentStock = newStocks[r.id].maxIWant;
			} else {
				currentStock = r.currentStock || 0;
				// currentStock = r.fullStock || 0;
			}

			return {...r, currentStock};
		});
		return {...e, resources};
	});
}

function eventsReducer(
    state: CalendarEventType[] = [],
    action: { type: string; payload?: any }
) {
    switch (action.type) {
        case EventsActionTypes.SET_EVENTS:
            // console.log("REDUX - events set all");
            return action.payload.value;
        case EventsActionTypes.SET_EVENT_START_END_DATE:
            // console.log("REDUX - events startEndDate");
            return setEventDate(
                state,
                action.payload.eventId,
                action.payload.startDate,
                action.payload.endDate
            );
        case EventsActionTypes.SET_EVENT_RESOURCES_START_END_DATES:
            // console.log("REDUX - events resourcesDates");
            return setResourcesDates(
                state,
                action.payload.eventId,
                action.payload.resourceUpdates,
                action.payload.updateEventDates
            );
        case EventsActionTypes.SET_EVENTS_UPDATED:
            // console.log("REDUX - events updated");
            return state.map((e) => {
                return { ...e, updated: action.payload.value };
            });
		case EventsActionTypes.SET_EVENT_RESOURCES:
			// console.log("REDUX - event resources updated");
			return setEventResources(
				state,
				action.payload.eventId,
				action.payload.resources,
			);
		case EventsActionTypes.SET_EVENT_RESOURCES_CURRENT_STOCK:
			// console.log("REDUX - event resources current stock updated");
			return setEventResourcesCurrentStock(
				state,
				action.payload.newStocks,
			);
		case EventsActionTypes.UPDATE_EVENT:
			// console.log("REDUX - update event");
			return state.map(event => event.id === action.payload.event.id ? {...event, ...action.payload.event} : event);
		case EventsActionTypes.ADD_EVENT:
			return [...state, action.payload.event];
        default:
            return state;
    }
}

export default eventsReducer;
