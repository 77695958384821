import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
		primary: {
			light: "rgba(20, 165, 235, 0.3)",
			main: "rgba(20, 165, 235, 0.4)",
			dark: "rgba(20, 165, 235, 0.5)",
			contrastText: "#fff",
		},
        // primary: {
        //     light: "#cbf7f0",
        //     main: "#a4f5e7",
        //     dark: "#44f2d7",
        //     contrastText: "#fff",
        // },
        secondary: {
            light: "#ffcbb5",
            main: "#f44336",
            dark: "#ff834f",
            contrastText: "#000",
        },
    },
    overrides: {
		// // @ts-ignore
		// MuiAutocomplete: {
		// 	inputRoot: {
		// 		'&&[class*="MuiOutlinedInput-root"] $input': {
		// 			padding: 0
		// 		}
		// 	}
		// },
        MuiFormControl: {
            root: {
                width: "100%",
            },
        },
        MuiButton: {
            root: {
                width: "100%",
            },
        },
        MuiCheckbox: {
            root: {
                color: "rgba(0, 0, 0, 0.45)",
            },
            colorSecondary: {
                "&.Mui-checked": {
                    color: "rgba(20, 165, 235, 0.5)",
                    // color: "#44f2d7",
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
            },
            input: {
                padding: "10px 14px",
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: "translate(14px, 12px) scale(1)",
            },
        },
        MuiSelect: {
            select: {
                "&:focus": {
                    backgroundColor: "transparent",
                },
            },
        },
        MuiDivider: {
            root: {
                marginTop: "15px",
                marginBottom: "15px",
            },
        },
    },
});

export default theme;
