import React from "react";
import { useSelector } from "react-redux";
import { format, getDaysInMonth } from "date-fns";

import Col from "../Layout/Col";
import Row from "../Layout/Row";
import { getTimeLineValues } from "../../helpers/calendarHelpers";
import {
    getCalendarStartDate,
    getWorkTimeEnd,
    getWorkTimeStart,
} from "../../redux/selectors/calendarSelectors";
import CalendarBodyPrintView from "./CalendarBodyPrintView";
import TimeLine from "../Calendar/TimeLine";

const MonthCalendarPrintView = () => {
    const calendarStartDate = useSelector(getCalendarStartDate);
    const workTimeStart = useSelector(getWorkTimeStart);
    const workTimeEnd = useSelector(getWorkTimeEnd);
    const daysInMonth = getDaysInMonth(calendarStartDate);
    const timeLineValues = getTimeLineValues(
        workTimeStart,
        workTimeEnd,
        "month",
        daysInMonth
    );

    return (
        <div className="calendar-container">
            <div className="calendar-head">
                <Row>
                    <Col className="calendar-head-event"></Col>
                    {Array.from(Array(daysInMonth).keys()).map((day) => (
                        <Col className="calendar-head-day" key={day + 1}>
                            {format(
                                new Date(calendarStartDate).setDate(day + 1),
                                "EEEEEE"
                            )}
                            <br />
                            {day + 1}.
                        </Col>
                    ))}
                </Row>
            </div>
            <TimeLine timeLineValues={timeLineValues} />
            <div className="calendar-body-container">
                <div className="calendar-body">
                    <div className="body-timeline">
                        {timeLineValues.map((tl, index) => (
                            <React.Fragment key={index}>
                                {tl.showLine && (
                                    <div
                                        className="line"
                                        style={{
                                            marginLeft: `${tl.step}px`,
                                        }}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                    <CalendarBodyPrintView />
                </div>
            </div>
        </div>
    );
};

export default MonthCalendarPrintView;
