import React, { FC } from "react";
import {
	TextField as UITextField,
	FormControl,
	FormLabel,
} from "@material-ui/core";

interface Props {
    value: string | null;
    onChange: (value: string | null) => void;
    label?: string;
    labelType?: "in" | "out";
    variant?: "standard" | "filled" | "outlined";
    readOnly?: boolean;
    disabled?: boolean;
    rows?: number;
    style?: React.CSSProperties;
}

function unmask(val: string | null) {
    return val !== "" ? val : null;
}

const TextField: FC<Props> = ({
    value,
    onChange,
    label,
    labelType = "in",
    variant = "outlined",
	readOnly = false,
    disabled = false,
    rows,
	style,
    ...props
}) => {
    return (
        <div className="field-container">
            <FormControl>
                {labelType === "out" && <FormLabel>{label}</FormLabel>}
                <UITextField
					inputProps={{
						style,
						readOnly,
                        disabled,
					}}
                    {...props}
                    value={value || ""}
                    onChange={(e) => onChange(unmask(e.target.value))}
                    variant={variant as any}
                    label={labelType === "in" ? label : ""}
                    multiline={!!rows}
                    rows={rows}
                />
            </FormControl>
        </div>
    );
};

export default TextField;
