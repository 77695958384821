import config from "../config.json";
import { CalendarSettingsType } from "../redux/reducers/calendarReducer";

function objectsEquals(x: any, y: any) {
    let objectsAreSame = true;
    for (let propertyName in x) {
        if (
            x[propertyName] instanceof Date &&
            y[propertyName] instanceof Date
        ) {
            if (x[propertyName].getTime() !== y[propertyName].getTime()) {
                objectsAreSame = false;
                break;
            }
        } else {
            if (x[propertyName] !== y[propertyName]) {
                objectsAreSame = false;
                break;
            }
        }
    }
    return objectsAreSame;
}

const filterTemplatesByBranchId = (templateGroups: any, branchId: any, templateGroupId?: any, calendarTemplateGroups?: string[]) => {
	if (!branchId) return templateGroups;
	let result: any = [];
	templateGroups.forEach((group: any) => {
		const templates = group.options.filter((template: any) => template.branchNr === branchId);
		if (templates.length) {
			result.push({...group, options: templates});
		}
	});

    if (templateGroupId) {
        result = result.filter((group: any) => group.templateGroupNr === templateGroupId);
    }

    if (calendarTemplateGroups && calendarTemplateGroups.length) {
        result = result.filter((group: any) => calendarTemplateGroups.includes(group.templateGroupNr));
    }

	return result;
}

const getCalendarTemplateGroups = (calendarSettings: CalendarSettingsType, calendarId: string|null) => {
    if (!calendarSettings || !calendarId) return [];
    if (!Object.keys(calendarSettings).includes(calendarId)) return [];
    const calendar = calendarSettings[calendarId];
    if (!calendar.templategroups) return [];

    return calendar.templategroups.split(',');
}

export const isDevEnvironment = new RegExp(config.devEnvs).test(window.location.host);

export { objectsEquals, filterTemplatesByBranchId, getCalendarTemplateGroups };
