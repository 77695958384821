import React, { FC } from "react";

interface Props {
    value: string | null;
    placeholder?: string;
    onChange: (value: string) => void;
    className?: string;
    style?: React.CSSProperties;
}

const EditableTitleField: FC<Props> = ({
    value,
    placeholder,
    onChange,
	className,
	style,
}) => {
    return (
		<input
			value={value || ''}
			placeholder={placeholder || ''}
			style={style}
			className={className}
			onChange={(e) => onChange(e.currentTarget.value)}
		/>
    );
};

export default EditableTitleField;
