import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { getCalendarDisplayType } from "../../redux/selectors/filterSelectors";
import DayCalendarPrintView from "./DayCalendarPrintView";
import WeekCalendarPrintView from "./WeekCalendarPrintView";
import MonthCalendarPrintView from "./MonthCalendarPrintView";
import Row from "../Layout/Row";
import Col from "../Layout/Col";
import ButtonField from "../Fields/ButtonField";
import { setPrintMode } from "../../redux/actions/printModeActions";
import "./calendarPrintView.css";

const CalendarPrintView = () => {
    const dispatch = useDispatch();
    const calendarDisplayType = useSelector(getCalendarDisplayType);
    const ref = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();

    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });

    const handleBack = () => {
        dispatch(setPrintMode(false));
    };

    return (
        <>
            <Row
                className="calendar-print-view-menu"
                justify="flex-end"
                gap="5px"
            >
                <Col width={10}></Col>
                <Col width={1}>
                    <ButtonField onClick={handleBack}>{t("back")}</ButtonField>
                </Col>
                <Col width={1}>
                    <ButtonField onClick={handlePrint}>
                        {t("print")}
                    </ButtonField>
                </Col>
            </Row>
            <div className="calendar-print-view" ref={ref}>
                {calendarDisplayType === "day" && <DayCalendarPrintView />}
                {calendarDisplayType === "week" && <WeekCalendarPrintView />}
                {calendarDisplayType === "month" && <MonthCalendarPrintView />}
            </div>
        </>
    );
};

export default CalendarPrintView;
