import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Icon from "../Icon";
import Row from "../Layout/Row";
import Col from "../Layout/Col";
import {
    composeEventRowId,
    groupEventsByTemplateId,
    groupResourcesByItemId,
} from "../../helpers/calendarHelpers";
import { getBranchFilter } from "../../redux/selectors/filterSelectors";
import { getOpenedDetails } from "../../redux/selectors/calendarSelectors";
import { ResourceType } from "../Calendar/CalendarResourceRow";
import { CalendarEventType } from "../Calendar/CalendarEventRow";
import TimeSlot from "../Calendar/TimeSlot";
import CalendarResourceRowPrintView from "./CalendarResourceRowPrintView";
import {
    addOpenedDetail,
    setOpenedDetails,
} from "../../redux/actions/calendarActions";

interface Props {
    events: CalendarEventType[];
    childEvents: CalendarEventType[];
    showDetailIcon?: boolean;
}

const CalendarEventRowPrintView: FC<Props> = ({
    events: templateEvents,
    childEvents,
    showDetailIcon = true,
}) => {
    const [id, setId] = useState("");
    const dispatch = useDispatch();
    const branch = useSelector(getBranchFilter);
    const openedDetails = useSelector(getOpenedDetails);
    const groupedChildEvents = groupEventsByTemplateId(childEvents, ["0"]);
    const resources = templateEvents.reduce((acc, tEvent) => {
        return [...acc, ...tEvent.resources];
    }, [] as ResourceType[]);
    const groupedResources = groupResourcesByItemId(resources);

    useEffect(() => {
        setId(composeEventRowId(templateEvents));
    }, [templateEvents]);

    const showDetail = useCallback(
        (id: string) => {
            if (!openedDetails.includes(id)) {
                dispatch(addOpenedDetail(id));
            }
        },
        [openedDetails, dispatch]
    );

    const hideDetail = (id: string) => {
        const details = openedDetails.filter((d) => d !== id);
        dispatch(setOpenedDetails(details));
    };

    return (
        <div className="calendar-event">
            <Row>
                <Col className="event-name">
                    <Row align="center" gap="2px">
                        {showDetailIcon && (
                            <Col width={1}>
                                {!openedDetails.includes(id) ? (
                                    <Icon
                                        name="add"
                                        size="22px"
                                        onClick={(e) => {
                                            showDetail(id);
                                        }}
                                    />
                                ) : (
                                    <Icon
                                        name="remove"
                                        size="22px"
                                        onClick={(e) => {
                                            hideDetail(id);
                                        }}
                                    />
                                )}
                            </Col>
                        )}
                        <Col width={5} className="calendar-text">
                            {`${templateEvents[0].name} ${
                                branch === null
                                    ? "(" + templateEvents[0].branchName + ")"
                                    : ""
                            }`}
                        </Col>
                    </Row>
                </Col>
                <div className="event-space">
                    {templateEvents.map((tEvent, index) => (
                        <TimeSlot
                            draggable={false}
                            resizable={false}
                            key={index}
                            id={tEvent.id}
                            className="event"
                            color={tEvent.color}
                            startDate={tEvent.startDate}
                            endDate={tEvent.endDate}
                            onDrag={() => {}}
                            onDragStop={() => {}}
                            onLeftResizerMouseDown={() => {}}
                            onRightResizerMouseDown={() => {}}
                            onDoubleClick={() => {}}
                        />
                    ))}
                </div>
            </Row>
            {openedDetails.includes(id) &&
                groupedResources
                    .sort((r1, r2) => {
                        return r1[0].itemId &&
                            r2[0].itemId &&
                            r1[0].itemId > r2[0].itemId
                            ? 1
                            : -1;
                    })
                    .map((resGroup, index) => (
                        <CalendarResourceRowPrintView
                            key={index}
                            resourceGroup={resGroup}
                        />
                    ))}
            {openedDetails.includes(id) &&
                groupedChildEvents
                    .sort((e1, e2) => {
                        return e1[0].templateId &&
                            e2[0].templateId &&
                            ((e1[0].templateId !== "0" &&
                                e1[0].templateId > e2[0].templateId) ||
                                (e1[0].templateId === "0" &&
                                    e1[0].id > e2[0].id))
                            ? 1
                            : -1;
                    })
                    .map((childEventGroup, index) => (
                        <CalendarEventRowPrintView
                            key={index}
                            events={childEventGroup}
                            childEvents={[]} // TODO: set children when more than 1 level event will be allowed
                        />
                    ))}
        </div>
    );
};

export default CalendarEventRowPrintView;
