import React, { FC } from "react";

import config from "../../config.json";

export interface TimeLineValue {
    value: string;
    step: number;
    showValue: boolean;
    showLine: boolean;
}

interface TimeLineProps {
    timeLineValues: TimeLineValue[];
}

const TimeLine: FC<TimeLineProps> = ({ timeLineValues }) => {
    return (
        <div
            className="timeline-day-container"
            style={{
                marginLeft: config.calendar.bodyLeftMargin,
            }}
        >
            {timeLineValues.map((timeLine, index) => {
                return (
                    <React.Fragment key={index}>
                        {timeLine.showValue && (
                            <div
                                className="timeline-time"
                                style={{
                                    marginLeft: `${timeLine.step}px`,
                                }}
                            >
                                {timeLine.value}
                            </div>
                        )}
                        {timeLine.showLine && (
                            <div
                                className="timeline-point"
                                style={{
                                    marginLeft: `${timeLine.step}px`,
                                }}
                            >
                                |
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default TimeLine;
