import {FiltersOptionsType, GrouppedTemplateType} from "../reducers/filterOptionsReducer";

export const getFilterOptions = (store: any): FiltersOptionsType => {
    return store.filterOptions;
};

export const getTemplateFilterOptions = (
    store: any
): { value: string; label: string }[] => {
    return store.filterOptions.template;
};

export const getGrouppedTemplatesFilterOptions = (
	store: any
): GrouppedTemplateType[] => {
	return store.filterOptions.grouppedTemplates;
};

export const getTemplateOptionsForApi = (
	store: any
): { value: string; label: string, billNr: string, branchNr: string, hostNr: string }[] => {
	return store.filterOptions.template;
};

export const getSupplierFilterOptions = (
    store: any
): { value: string; label: string }[] => {
    return store.filterOptions.suppliers;
};
