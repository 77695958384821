import {BranchItemsActionTypes} from "../actionTypes";
import {BranchItemType} from "../../helpers/dataProcessor";
import {AvailableResourceQtyType} from "../../helpers/calendarHelpers";
import { ResourceItemType } from "../../helpers/api";

export const setBranchItems = (value: BranchItemType[]) => ({
	type: BranchItemsActionTypes.SET_BRANCHITEMS,
	payload: {
		value,
	},
});

export const setBranchItemsConflicts = (value: string[]) => ({
	type: BranchItemsActionTypes.SET_BRANCHITEMS_CONFLICTS,
	payload: {
		value,
	},
});

export const setAvailableResourcesQty = (value: AvailableResourceQtyType) => ({
	type: BranchItemsActionTypes.SET_AVAILABLE_RESOURCES_QTY,
	payload: {
		value,
	},
});

export const setResourceItems = (value: ResourceItemType[]) => ({
	type: BranchItemsActionTypes.SET_RESOURCE_ITEMS,
	payload: {
		value,
	},
});
