import React, { FC } from "react";

import { ResourceType } from "../Calendar/CalendarResourceRow";
import TimeSlot from "../Calendar/TimeSlot";
import Col from "../Layout/Col";
import Row from "../Layout/Row";

interface ResourceTypeRowProps {
    resourceGroup: ResourceType[];
}

const CalendarResourceRowPrintView: FC<ResourceTypeRowProps> = ({
    resourceGroup,
}) => {
    return (
        <Row>
            <Col className="event-name">
                <Row align="center" gap="2px">
                    <Col width={1}></Col>
                    <Col width={6} className="calendar-text">
                        {resourceGroup[0].name}
                    </Col>
                </Row>
                {/* {`${name}: ${startDate && format(startDate, "H:mm")} - ${
                    endDate && format(endDate, "H:mm")
                }`} */}
            </Col>
            <div className="event-space">
                {resourceGroup.map((resource, index) => (
                    <React.Fragment key={index}>
                        {resource.startDate && resource.endDate && (
                            <TimeSlot
                                draggable={false}
                                resizable={false}
                                id={resource.id}
                                startDate={resource.startDate}
                                endDate={resource.endDate}
                                color={resource.color || undefined}
                                onDrag={() => {}}
                                onDragStop={() => {}}
                                onLeftResizerMouseDown={() => {}}
                                onRightResizerMouseDown={() => {}}
                            >
                                {/* <div style={{ userSelect: "none", overflow: "auto" }}> */}
                                {/* <SelectField<string>
                                    value={null}
                                    onChange={() => {}}
                                    emptyOption={false}
                                    options={[
                                        {
                                            value: null,
                                            label: `${minCapacity}/${freeCapacity}/${maxCapacity}`,
                                        },
                                        { value: "5", label: "5" },
                                        { value: "10", label: "10" },
                                        { value: "15", label: "15" },
                                        { value: "20", label: "20" },
                                    ]}
                                /> */}
                                {/* </div> */}
                            </TimeSlot>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </Row>
    );
};

export default CalendarResourceRowPrintView;
