import React, { FC } from "react";
import { FixedSizeList as List } from "react-window";
import { Autocomplete } from "@material-ui/lab";

import TextField from "../TextField";

export interface SearchOption<T extends string | number> {
    value: T | null;
    label: string;
    group?: string;
}

interface Props<T extends string | number> {
    value: T | null;
    onChange: (value: T | null) => void;
    label?: string;
    labelType?: "in" | "out";
    searchOptions: SearchOption<T>[];
    clearOnBlur?: boolean;
}

const LISTBOX_PADDING = 8; // px

const Row: FC<any> = ({ data, index, style }) => {
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
            whiteSpace: "nowrap",
        },
    });
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref: any) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref: any
) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 50;

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <List
                    itemData={itemData}
                    height={itemCount > 8 ? 8 * itemSize : itemCount * itemSize}
                    width="100%"
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={itemSize}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {Row}
                </List>
            </OuterElementContext.Provider>
        </div>
    );
});

function SearchField<T extends string | number>({
    value,
    onChange,
    label,
    labelType,
    searchOptions,
    clearOnBlur = false,
}: Props<T>) {
    const opts = searchOptions;
    const thisOption = opts.find((opt) => opt.value === value);
    const val = thisOption ? thisOption : { value: null, label: "" };
    return (
        <Autocomplete
            value={val}
            onChange={(e, value) => {
                onChange(value ? value.value : null);
            }}
            options={opts}
            getOptionLabel={(option) => option.label}
			// groupBy={(option) => option?.group ? option.group : ''}
            clearOnBlur={clearOnBlur}
            size="small"
            disableListWrap
            ListboxComponent={ListboxComponent as any}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    labelType={labelType}
                    value={value + ""}
                    onChange={() => {}}
                />
            )}
        />
    );
}

export default SearchField;
