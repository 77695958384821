import React, { FC } from "react";
import NumberFormat from 'react-number-format';

interface Props {
    value: number | null;
    onChange: (value: number | null) => void;
    decimals?: number;
	readOnly?: boolean;
    disabled?: boolean;
}

const PriceField: FC<Props> = ({
    value,
    onChange,
    decimals = 0,
    readOnly = false,
    disabled = false,
}) => {
    return (
        <div className="field-container">
            <div className="MuiFormControl-root">
                <div className="MuiFormControl-root MuiTextField-root">
                    <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                    <NumberFormat
                        value={value !== null ? value : ""}
                        onValueChange={(val) => onChange(parseFloat(val.formattedValue))}
                        style={{direction: 'rtl'}}
                        readOnly={readOnly}
                        disabled={disabled}
                        decimalScale={decimals}
                        fixedDecimalScale={true}
                        className="MuiInputBase-input MuiOutlinedInput-input"
                    />
                    <fieldset aria-hidden="true" className="jss1 MuiOutlinedInput-notchedOutline">
                        <legend className="jss2" ><span>​</span></legend>
                    </fieldset>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PriceField;
