import { CalendarEventType } from "../../components/Calendar/CalendarEventRow";

export const getEvents = (store: any): CalendarEventType[] => {
    return store.events;
};

export const getEventById = (
    store: any,
    id: string
): CalendarEventType | null => {
    return store.events.find((e: CalendarEventType) => e.id === id) || null;
};
