import React, { FC } from "react";

import "./loader.css";

interface Props {
    loading: boolean;
}

const Loader: FC<Props> = ({ loading }) => {
    return loading ? (
        <div
            className="loader-bg"
            style={{ display: loading ? "block" : "none" }}
        >
            <div className="loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default Loader;
